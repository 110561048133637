import React, {useMemo, useState} from "react";

import {Container, InfoTooltip, Loader, MaxBtn, MaxLink} from "../core/components";
import {CheckboxInput, DateTimeInput, EnumInput, InputContainer, LightContainer, MyModal, SelectfieldInput, TextfieldInput} from "../core/input_fields";
import {FormContextWrapper} from "../core/form_context";
import {maxiGet, maxiPost} from "../core/maxios";
import Status from "../core/status";
import UserHistory from "../user/userHistory";
import {OfferType, OfferTypeMap} from "../core/enums";
import {FaTrashAlt, FaArrowsAlt, FaUndo} from "react-icons/all";
import {individualSexChoices, SteingruberSelect, teamSexChoices, translation} from "./steingruberConsts";
import "./style.sass"
import {Link} from "react-router-dom";
import {TURNRImport} from "./offerHelpers";

const fields = {
    isCompetitionPart: "Wettkampfteil",
    type: "Art",
    description: "Bezeichnung",
    price: "Preis",
    discountedPrice: "siehe unten",
    onlyOfficials: "Nur Offizielle?",
    bookable: "Eigenständig buchbar",
    maxParts: "Maximale Teilnehmer",
    minAge: "Alter ab",
    maxAge: "Alter bis",
    allowedSex: "Geschlecht",
    automaticAssignment: "automatische Zuordnung",
    turnrSelection: "Turnr Selection (this text is not shown)",
    minTeamSize: "Minimale Teamgröße",
    maxTeamSize: "Maximale Teamgröße",
    minFemaleParticipants: "Minimale Anzahl an weiblichen Teilnehmern pro Team",
    minMaleParticipants: "Minimale Anzahl an männlichen Teilnehmern pro Team",
    ageGroups: "Altersklassen (durch Beistriche getrennt, minimales Alter der Altersklasse)",
};

const apparatusesSetChoices = {
    turn10: {
        W: [
            {value: "turn10_5_basis", label: "Basis - 5 Geräte"},
            {value: "turn10_5_oberstufe", label: "Oberstufe- 5 Geräte"},
            {value: "turn10_4", label: "4 Geräte (Schule)"},
        ],
        M: [
            {value: "turn10_5_basis", label: "Basis - 5 Geräte"},
            {value: "turn10_5_oberstufe", label: "Oberstufe- 5 Geräte"},
            {value: "turn10_7_basis", label: "Basis - 7 Geräte"},
            {value: "turn10_7_oberstufe", label: "Oberstufe- 7 Geräte"},
            {value: "turn10_4", label: "4 Schule"},
        ],
        X: [
            {value: "turn10_5_basis", label: "Basis - 5 Geräte"},
            {value: "turn10_5_oberstufe", label: "Oberstufe- 5 Geräte"},
        ]
    },
    kunstturnen: {
        W: [
            {value: "kinderstufen", label: "Kinderstufen"},
            {value: "cdp", label: "Allgemein nach Code de Pointage"},
        ],
        M: [
            {value: "mkunstturnen", label: "Kunstturnen männlich"},
            {value: "mkunstturnenjugend", label: "Kunstturnen männlich mit Kraft/Koordination"},
        ],
    }
};

function OfferConfigExplanation({}) {
    return
}

export default function OfferEdit({event, offer, offers, reload, close}) {
    const [{error, loading, success}, setStatusVar] = useState({});
    const [state, setState] = useState({offer})
    const handleSubmit = () => {
        !loading && maxiPost("/event/offer", state, {setStatusVar})
            .then(() => {
                reload()
                close()
            })
    }
    useMemo(() => offer.ID && maxiGet(`/event/${state.offer.event_ID}/offer/${offer.ID}`).then(setState), [offer.ID])
    return <LightContainer name={"Option " + (offer.ID ? "bearbeiten" : "neu hinzufügen")}>

        <FormContextWrapper value={{state, setState: a => setState(b => ({...b, ...a})), updateState: (a, b, c) => setState(old => ({...old, [a]: {...old[a], [b]: c}}))}} onSubmit={handleSubmit}>
            {
                Object.entries(fields)
                    .filter(([f,]) => state.offer.isCompetitionPart == 1 || !["individualOrTeam", "ruleset", "numOfIncludedScores", "dynamicIncludedScores", "minTeamSize", "maxTeamSize", "minFemaleParticipants", "minMaleParticipants", "automaticAssignment"].includes(f) /*|| (state.offer.isCompetitionPart==2 && ["maxAge", "minAge"].includes(f))*/)
                    //.filter(([f,]) => state.offer.isCompetitionPart == 1 || state.offer.isCompetitionPart == 2 || !["maxAge", "minAge", "allowedSex"].includes(f) /*|| (state.offer.isCompetitionPart==2 && ["maxAge", "minAge"].includes(f))*/)
                    .map(([f, fieldName], i) => {
                        switch (f) {
                            case "onlyOfficials":
                                if (!!state.offer.isCompetitionPart /*if not undefined or 0 */ && state.offer.isCompetitionPart != 0) return null
                                return <>
                                    <SelectfieldInput name={fieldName} tag={"offer_" + f} selectives={[[0, "beide"], [1, "nur Offizielle"], [2, "nur nicht Offizielle"]]}/>
                                    <br/>
                                </>;
                            case "isCompetitionPart":
                                return <>
                                    <SelectfieldInput name={fieldName} tag={"offer_" + f} selectives={[[0, "kein Wettkampfteil"], [1, "Punktewertung"], [2, "Rangwertung"], [3, "Umlauf"]]}/>
                                    {
                                        state.offer.isCompetitionPart == 2 && (state.offer.ID > 0 ? <><Link to={"/bewerbe/" + state.offer?.event_ID}>Disziplinen bearbeiten</Link><br/></> : <><em>Disziplinen bearbeiten (erst nach erstem Speichern möglich)</em><br/></>)
                                    }
                                    <br/>
                                </>;
                            case "type":
                                //if (!!state.offer.isCompetitionPart /*if not undefined or 0 */) return null
                                return <>
                                    <EnumInput name={fieldName} tag={"offer_" + f} type="reactselect" selectives={OfferType.filter(o => !!offer.parent_ID || o.value !== OfferTypeMap.selection)}/>
                                    <br/>
                                </>;
                            case "bookable":
                                if (state.offer.isCompetitionPart == 1 /*if not undefined or 0 */) return null
                                return <>
                                    <CheckboxInput name={fieldName} tag={"offer_" + f}/>
                                    <br/>
                                </>;
                            case "automaticAssignment":
                                if (state.offer.isCompetitionPart != 1 || state.offer.individualOrTeam !== "E") return null
                                return <>
                                    <CheckboxInput name={<>{fieldName} <InfoTooltip>Falls dies aktiviert ist (Standard), wird dieser Einzelwettkampfteil für die automatische Zuordnung bei Buchung eines Mannschafts-Wettkampfteils herangezogen.</InfoTooltip></>} tag={"offer_" + f}/>
                                    <br/>
                                </>;
                            case "allowedSex":
                                if (state.offer.isCompetitionPart != 2) {
                                    return null
                                }
                                return <>
                                    <SteingruberSelect {...{f: "allowedSex", fieldName: "Geschlecht", toSelect: teamSexChoices}}/>
                                    <br/>
                                </>
                            case "turnrSelection":
                                return <>
                                    {
                                        state.offer.isCompetitionPart == 1 && <>
                                            {
                                                offer.ID && <>
                                                    <li><b>Einzel oder Team</b>: {{"T": "Team", "E": "Einzel"}[state.offer.individualOrTeam]}</li>
                                                    <li><b>Zugelassenes Geschlecht</b>: {{"M": "männlich", "W": "weiblich", "X": "Gemischt"}[state.offer.allowedSex]}</li>
                                                    {
                                                        state.offer.dynamicIncludedScores > 0 && <li><b>Falls ungleich 0, Anzahl an nicht berücksichtigten vollen Paaren</b>: {state.offer.dynamicIncludedScores}</li>
                                                    }
                                                    <li><b>{state.offer.dynamicIncludedScores > 0 ? "minimale Anzahl an berücksichtigten Paaren" : "berücksichtigte Einzelwertungen pro Gerät"}</b>: {state.offer.numOfIncludedScores}</li>
                                                    <li><b>Best Of</b>: {state.offer.bestOfApparatusCount}</li>
                                                    <li><b>Regelsatz</b>: {state.offer.ruleset || state.offer.teamRuleset}</li>
                                                </>
                                            }


                                            {!offer.ID && state.offer.isCompetitionPart == 1 && <>

                                                <SelectfieldInput name={"Art"} tag={"offer_" + "kind"} type="reactselect" selectives={[
                                                    {value: "turn10", label: "Turn10"},
                                                    {value: "kunstturnen", label: "Kunstturnen"},
                                                    {value: "mixed", label: "Gemischt (inkl. Leichtathletik)"},
                                                ]}/>

                                                <SelectfieldInput name={"Einzel oder Team"} tag={"offer_individualOrTeam"} type="reactselect" selectives={[
                                                    {value: "E", label: "Einzel"},
                                                    {value: "T", label: "Team"},
                                                ]}/>

                                                {
                                                    state.offer.individualOrTeam === "T" && <>
                                                        <TextfieldInput class={"form-control "} type={"number"} width={500} name={"Falls ungleich 0, Anzahl an nicht berücksichtigten vollen Paaren"} tag={"offer_dynamicIncludedScores"}/>
                                                        <TextfieldInput class={"form-control "} type={"number"} width={500} name={state.offer.dynamicIncludedScores > 0 ? "minimale Anzahl an berücksichtigten Paaren" : "berücksichtigte Einzelwertungen pro Gerät"}
                                                                        tag={"offer_numOfIncludedScores"}/>
                                                        <br/>
                                                    </>
                                                }
                                                <SteingruberSelect {...{f: "allowedSex", fieldName: "Geschlecht", toSelect: state.offer.individualOrTeam === "T" || state.offer.kind === "mixed" ? teamSexChoices : individualSexChoices}}/>
                                                {
                                                    state.offer.kind === "mixed" ?
                                                        <SelectApparatusSet/> :
                                                        <>
                                                            <SelectfieldInput name={"Geräteoptionen"} tag={"offer_apparatusSet"} type="reactselect" selectives={
                                                                (apparatusesSetChoices[state.offer.kind] || {})[state.offer.allowedSex] || []
                                                            }/>

                                                            <SelectfieldInput name={"Sprungmodus"} tag={"offer_jumpingMode"} type="reactselect" selectives={[
                                                                {value: "1", label: "1 Sprung"},
                                                                {value: "2b", label: "2 Sprünge besserer Sprung"},
                                                                {value: "2a", label: "2 Sprünge - Durchschnitt"},
                                                                {value: "2s", label: "2 Sprünge - Summe"},
                                                            ]}/>
                                                        </>
                                                }
                                            </>
                                            }

                                            {
                                                state.offer.apparatuses?.length > 0 && <>
                                                    <h3>Geräte</h3>
                                                    {
                                                        state.offer.apparatuses?.map((a, i) => <li>
                                                            {translation.apparatus[state["apparatus" + i].apparatusCode?.toLowerCase()]}, Versuche: {[state["apparatus" + i].count]}
                                                            {
                                                                state["apparatus" + i].count > 1 && <>,&nbsp;
                                                                    {translation.scoreMode[state["apparatus" + i].scoreMode.toLowerCase()] ?? state["apparatus" + i].scoreMode}
                                                                </>
                                                            }
                                                        </li>)
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            case "discountedPrice":
                                return <>
                                    <TextfieldInput class={"form-control "} width={500} name={<>{event?.prebookingEnd?"Preis für Frühbucher":`Preis für Mitglieder`}</>} tag={"offer_" + f}/>
                                    <br/>
                                </>;
                            default:
                                if (state.offer.isCompetitionPart == 3 && !["description"].includes(f)) return null
                                if (state.offer.isCompetitionPart != 2 && ["ageGroups"].includes(f)) return null
                                if (f.endsWith("Start") || f.endsWith("End")) {
                                    return <InputContainer>
                                        <DateTimeInput name={fieldName} midNight tag={"offer_" + f}/>
                                        {i % 2 === 0 && <br/>}
                                    </InputContainer>;
                                }
                                if ((f.endsWith("TeamSize") || f.endsWith("Participants")) && state.offer.individualOrTeam === "E") {
                                    return null;
                                }
                                return <>
                                    <TextfieldInput class={"form-control "} width={500} name={fieldName} tag={"offer_" + f}/>
                                    <br/>
                                </>;
                        }

                    })}
            <br/>


            <Status type={"error"} text={error}/>
            <MaxBtn>Speichern</MaxBtn><Loader loading={loading}/>
        </FormContextWrapper>

        {
            offer.ID && <>
                <MaxBtn onClick={() => {
                    if (window.confirm("wirklich löschen?")) {
                        maxiPost(`/event/${offer.event_ID}/offer/delete`, {ID: offer.ID}, {setStatusVar}).then(reload)
                    }
                }}>
                    <FaTrashAlt/> löschen
                </MaxBtn>&nbsp;&nbsp;
                <MoveOffer {...{offers, offer, reload}}/>
            </>
        }
        {
            offer.isCompetitionPart === 3 && <p>
                <MaxLink to={`/veranstaltungen/${offer.event_ID}/umlaufzuordnung/teilnehmer`}>Umlaufzuordnung Teilnehmer</MaxLink>
                &nbsp;
                <MaxLink to={`/veranstaltungen/${offer.event_ID}/umlaufzuordnung/kampfrichter`}>Umlaufzuordnung Kampfrichter</MaxLink>
                &nbsp;
                <MaxLink to={`/veranstaltungen/${offer.event_ID}/kampfrichterzuordnung`}>Kampfrichterzuordnung</MaxLink>
            </p>
        }
        <LightContainer name={"Hinweise"}>
            Optionen, wo mehrere Auswahlmöglichkeiten zur Verfügung stehen, müssen Unteroptionen (Typ Auswahl) einer Pflicht-Option sein.<br/>
            Außerdem müssen alle Optionen auf einer Ebene die gleichen Einstellungen hinsichtlich Offizielle erlauben haben.
        </LightContainer>
        <br/>
        {
            offer.ID && <TURNRImport event={event} reload={reload} parent_ID={offer.ID}/>
        }
        <br/>
        {
            offer.ID && <LightContainer name={"Veränderungen"}>
                <UserHistory userID={offer.ID} personType={"offer"}/>
            </LightContainer>
        }
    </LightContainer>


}

function MoveOffer({offer, offers, reload}) {
    const [state, setState] = useState({new: {}});
    const [{error}, setStatusVar] = useState({});

    return <MyModal trigger={<MaxBtn><FaArrowsAlt/> verschieben</MaxBtn>}>
        {
            close => <>
                <FormContextWrapper value={{state, setState, updateState: (a, b, c) => setState({new: {parent: c}})}}>

                    <Status type={"error"} text={error}/>
                    <SelectfieldInput type={"reactselect"} name={"Unterordnen zu"} tag={"new_parent"} selectives={offers.map(offer => ({label: offer.description, value: offer.ID}))}/>
                    <MaxBtn onClick={() => maxiPost(`/event/${offer.event_ID}/offer/move`, {offer_ID: offer.ID, parent_ID: state.new?.parent}, {setStatusVar}).then(() => {
                        close()
                        reload()
                    })}>
                        verschieben
                    </MaxBtn>
                </FormContextWrapper>
            </>
        }
    </MyModal>
}

function SelectApparatusSet({}) {
    const [{loading, error}, setStatusVar] = useState({})
    const [apparatusSets, setApparatusSets] = useState([]);
    const loadSets = () => maxiGet("/turnr/apparatuses/sets", {setStatusVar}).then(setApparatusSets)
    useMemo(loadSets, [])
    return <div>
        <Status type={"error"} text={error}/>
        <SelectfieldInput name={"Gerätesatz"} tag={"offer_apparatusSet"} type="reactselect" inline selectives={
            apparatusSets.map(({identifier, id}) => ({value: id, label: identifier}))
        }/>
        &nbsp;
        &nbsp;
        <a target={"_blank"} href={"/gerätesätze"}>Gerätesätze verwalten</a> <em onClick={loadSets}><FaUndo/></em>
        <br/>
    </div>
}
