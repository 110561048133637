import {SelectfieldInput} from "../core/input_fields";
import React from "react";

export const translation = {
    gender: {
        female: "Weiblich",
        male: "Männlich",
        mixed: 'Mixed'
    },
    apparatus: {
        all: 'Alle Geräte',
        noapparatus: 'Kein Gerät',
        bb: 'Balken',
        pb: 'Barren',
        fx: 'Boden',
        mt: 'Minitramp',
        ph: 'Pauschenpferd',
        hb: 'Reck',
        sr: 'Ringe',
        vt: 'Sprung',
        ub: 'Stufenbarren',
        ubhb: "Stuba / Reck",
        bbpb: 'Balken / Barren',
        'vtmt': 'Sprung / Tramp',
        ...{
            'bb': 'Balken',
            'pb': 'Barren',
            'fx': 'Boden',
            'mt': 'Minitramp',
            'ph': 'Pauschenpferd',
            'hb': 'Reck',
            'sr': 'Ringe',
            'vt': 'Sprung',
            'ub': 'Stufenbarren',
            '100m': '100m Lauf',
            '75m': '75m Lauf',
            '60m': '60m Lauf',
            '50m': '50m Lauf',
            '1000m': '1000m Lauf',
            '3000m': '3000m Lauf',
            'wssws': 'Stand-/Weitsprung',
            'wss': 'Weitsprung',
            'sws': 'Standweitsprung',
            'hs': 'Hochsprung',
            'k7': 'Kugelstoß 7,25kg',
            'k6': 'Kugelstoß 6,0kg (6,25kg)',
            'k5': 'Kugelstoß 5,0kg',
            'k4': 'Kugelstoß 4,0kg',
            'k3': 'Kugelstoß 3,0kg',
            'k2': 'Kugelstoß 2,0kg',
            'sb': 'Schlagball',
            'slb15': 'Schleuderball 1,5kg',
            'slb10': 'Schleuderball 1,0kg',
            'dis': 'Diskus 2,0kg',
            'noc': 'Nockenball',
            's25b': '25m Brustschwimmen',
            's50b': '50m Brustschwimmen',
            's50f': '50m Freistilschwimmen',
            's50k': '50m Kraul',
            's100b': '100m Brustschwimmen',
            's100k': '100m Kraul',
            '800m': '800m Lauf',
            'spe': 'Speer 600g',
            'vor': 'Vortex (Heuler)',

            'k2k3k4k5k6k7vor': 'Kugel/Vortex',
            'p100': 'Pendelstaffel 100m',
            'p75': 'Pendelstaffel 75m',
            'p60': 'Pendelstaffel 60m',
            'sst50b': 'Schwimmstaffel 50m',
            'sst33b': 'Schwimmstaffel 33m',
            'sst25b': 'Schwimmstaffel 25m',
            'sst20f': 'Schwimmstaffel 20m F',
            'sing': 'Singen',
            'tanz': 'Tanz',
            'singtanz': 'Singen / Tanz',
            'raet': 'Rätsellauf',
            'kk': 'Kraft / Koordination',
            'sinp': 'Singen Pflicht',
            'sink': 'Singen Kür',
            'tanp': 'Tanzen Pflicht',
            'tank': 'Tanzen Kür',
            'rede': 'Redewettbewerb',
        }
    },
    ruleset: {
        kinderweiblich: 'Kunstturnen - Kinderstufen weiblich',
        standardkunstturnen: 'Kunstturnen allgemein',
        turn10: 'Turn10',
        turn10basis: 'Turn10 - Basisstufe',
        turn10ober: 'Turn10 - Oberstufe'
    },
    scoreMode: {
        max: 'Bessere Wertung',
        average: 'Durchschnittswertung',
        sum: 'Summe',
    },
}
export const apparatusChoices = [
    {id: null, name: 'apparatus.noapparatus'},
    {id: 'BB', name: 'apparatus.bb'},
    {id: 'PB', name: 'apparatus.pb'},
    {id: 'FX', name: 'apparatus.fx'},
    {id: 'MT', name: 'apparatus.mt'},
    {id: 'PH', name: 'apparatus.ph'},
    {id: 'HB', name: 'apparatus.hb'},
    {id: 'SR', name: 'apparatus.sr'},
    {id: 'VT', name: 'apparatus.vt'},
    {id: 'UB', name: 'apparatus.ub'},
    {id: 'ubhb', name: 'apparatus.ubhb'},
    {id: 'bbpb', name: 'apparatus.bbpb'},
    {id: 'vtmt', name: 'apparatus.vtmt'},
];
export const turnGeraete = {
    bb: 'Balken',
    pb: 'Barren',
    fx: 'Boden',
    mt: 'Minitramp',
    ph: 'Pauschenpferd',
    hb: 'Reck',
    sr: 'Ringe',
    vt: 'Sprung',
    ub: 'Stufenbarren',
    ubhb: "Stuba / Reck",
    bbpb: 'Balken / Barren',
    'kk': 'Kraft / Koordination',
    'vtmt': 'Sprung / Tramp',
}
export const rulesetChoices = [
    {id: 'KinderWeiblich', name: 'ruleset.kinderweiblich'},
    {id: 'StandardKunstTurnen', name: 'ruleset.standardkunstturnen'},
    {id: 'Turn10Basis', name: 'ruleset.turn10basis'},
    {id: 'Turn10Ober', name: 'ruleset.turn10ober'}
];

export const teamRulesetChoices = [
    {id: 'Turn10Basis', name: 'ruleset.turn10basis'},
    {id: 'Turn10Ober', name: 'ruleset.turn10ober'}
];

export const scoreModeChoices = [
    {id: 'MAX', name: 'scoreMode.max'},
    {id: 'AVERAGE', name: 'scoreMode.average'}
];

export const dtypeChoices = [
    {id: '.IndividualCompetitionPartDto', name: 'dtype.individual'},
    {id: '.TeamCompetitionPartDto', name: 'dtype.team'}
];

export const individualSexChoices = [
    {id: 'W', name: 'gender.female'},
    {id: 'M', name: 'gender.male'},
];

export const teamSexChoices = [
    {id: 'W', name: 'gender.female'},
    {id: 'M', name: 'gender.male'},
    {id: 'X', name: 'gender.mixed'}
];

export const ignorantEval = (toEval) => {
    try {
        return eval(toEval)
    } catch (e) {
        return ""
    }
}

export const SteingruberSelect = ({f, fieldName, noLabel, toSelect, scope = "offer"}) => (
    <SelectfieldInput name={fieldName}
                      tag={scope + "_" + f}
                      noLabel={noLabel}
                      inline
                      type="reactselect"
                      selectives={(toSelect).map(a => ({value: a.id, label: ignorantEval("translation?." + a.name)}))}
    />);

export const SteingruberSelect2 = ({f, fieldName, noLabel, toSelect, scope = "offer"}) => (
    <SelectfieldInput name={fieldName}
                      tag={scope + "_" + f}
                      noLabel={noLabel}
                      inline
                      type="reactselect"
                      selectives={(toSelect).map(a => ({value: a.id, label: a.name}))}
    />);
